@import '../../assets/scss/variable.scss';

.loader-wrapper {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 9;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ffffff;
}
.loader {
    width : 70px;
    height: 70px;
    position: relative;
    border-radius: 50%;
    display: inline-block;
    border-top: 5px solid $secondary-color;
    border-right: 5px solid transparent;
    animation: rotation 1s linear infinite;
    &:after {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width : 70px;
      height: 70px;
      border-radius: 50%;
      border-left: 5px solid $primary-color;
      border-bottom: 5px solid transparent;
      animation: rotation 0.5s linear infinite reverse;
    }
  }

/* keyFrames */ 

@keyframes rotation {
    0% { transform: rotate(0deg) }
    100% { transform: rotate(360deg) }
}