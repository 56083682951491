	/*
  	Flaticon icon font: Flaticon
  	Creation date: 03/03/2020 12:51
  	*/

@font-face {
  font-family: "Flaticon";
  src: url("../../fonts/flaticon/Flaticon.eot");
  src: url("../../fonts/flaticon/Flaticon.eot?#iefix") format("embedded-opentype"),
       url("../../fonts/flaticon/Flaticon.woff2") format("woff2"),
       url("../../fonts/flaticon/Flaticon.woff") format("woff"),
       url("../../fonts/flaticon/Flaticon.ttf") format("truetype"),
       url("../../fonts/flaticon/Flaticon.svg#Flaticon") format("svg");
  font-weight: normal;
  font-style: normal;
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: "Flaticon";
    src: url("../../fonts/flaticon/Flaticon.svg#Flaticon") format("svg");
  }
}

[class^="flaticon-"]:before, [class*=" flaticon-"]:before,
[class^="flaticon-"]:after, [class*=" flaticon-"]:after {   
font-family: Flaticon;
font-style: normal;
}

.flaticon-paper-plane:before { content: "\f100"; }
.flaticon-design:before { content: "\f101"; }
.flaticon-chart:before { content: "\f102"; }
.flaticon-audio:before { content: "\f103"; }
.flaticon-order:before { content: "\f104"; }
.flaticon-app-development:before { content: "\f105"; }
.flaticon-data:before { content: "\f106"; }
.flaticon-group:before { content: "\f107"; }
.flaticon-rocket:before { content: "\f108"; }
.flaticon-diamond:before { content: "\f109"; }
.flaticon-document:before { content: "\f10a"; }
.flaticon-support:before { content: "\f10b"; }
.flaticon-phone:before { content: "\f10c"; }
.flaticon-world:before { content: "\f10d"; }
.flaticon-analytics:before { content: "\f10e"; }
.flaticon-target:before { content: "\f10f"; }
.flaticon-idea:before { content: "\f110"; }
.flaticon-idea-1:before { content: "\f111"; }
.flaticon-return:before { content: "\f112"; }
.flaticon-trajectory:before { content: "\f113"; }
.flaticon-eye:before { content: "\f114"; }
.flaticon-icon-751463:before { content: "\f115"; }
.flaticon-icon-253674:before { content: "\f116"; }
.flaticon-icon-149294:before { content: "\f117"; }
.flaticon-pin:before { content: "\f118"; }
.flaticon-like:before { content: "\f119"; }
.flaticon-profit:before { content: "\f11a"; }
.flaticon-console:before { content: "\f11b"; }
.flaticon-wifi:before { content: "\f11c"; }
.flaticon-smartphone:before { content: "\f11d"; }
.flaticon-podcast:before { content: "\f11e"; }
.flaticon-cctv:before { content: "\f11f"; }
.flaticon-icon-1243560:before { content: "\f120"; }
.flaticon-piggy-bank:before { content: "\f121"; }
.flaticon-icon-626443:before { content: "\f122"; }
.flaticon-icon-160200:before { content: "\f123"; }
.flaticon-thunder:before { content: "\f124"; }
.flaticon-car:before { content: "\f125"; }
.flaticon-trophy:before { content: "\f126"; }
.flaticon-strong:before { content: "\f127"; }
.flaticon-envelope:before { content: "\f128"; }
.flaticon-radio:before { content: "\f129"; }
.flaticon-trolley:before { content: "\f12a"; }
.flaticon-icon-149196:before { content: "\f12b"; }
.flaticon-heart:before { content: "\f12c"; }
.flaticon-icon-149103:before { content: "\f12d"; }
.flaticon-mouse:before { content: "\f12e"; }
.flaticon-watch:before { content: "\f12f"; }
.flaticon-power:before { content: "\f130"; }
.flaticon-lamp:before { content: "\f131"; }
.flaticon-router:before { content: "\f132"; }
.flaticon-invention:before { content: "\f133"; }
.flaticon-cloud:before { content: "\f134"; }
.flaticon-film:before { content: "\f135"; }
.flaticon-brain:before { content: "\f136"; }
.flaticon-air-conditioner:before { content: "\f137"; }
.flaticon-monitor:before { content: "\f138"; }
.flaticon-pendrive:before { content: "\f139"; }
.flaticon-solar-energy:before { content: "\f13a"; }
.flaticon-server:before { content: "\f13b"; }
.flaticon-help:before { content: "\f13c"; }
.flaticon-hard-drive:before { content: "\f13d"; }
.flaticon-seo-and-web:before { content: "\f13e"; }
.flaticon-tools-and-utensils:before { content: "\f13f"; }
.flaticon-electrician:before { content: "\f140"; }
.flaticon-add-user:before { content: "\f141"; }
.flaticon-snapchat:before { content: "\f142"; }
.flaticon-monitor-1:before { content: "\f143"; }
.flaticon-wechat:before { content: "\f144"; }
.flaticon-download:before { content: "\f145"; }
.flaticon-share:before { content: "\f146"; }
.flaticon-left-chevron:before { content: "\f147"; }
.flaticon-right-chevron:before { content: "\f148"; }
.flaticon-arrow:before { content: "\f149"; }
.flaticon-left:before { content: "\f14a"; }
.flaticon-twitter:before { content: "\f14b"; }
.flaticon-pinterest:before { content: "\f14c"; }
.flaticon-left-1:before { content: "\f14d"; }
.flaticon-messenger:before { content: "\f14e"; }
.flaticon-drive:before { content: "\f14f"; }
.flaticon-linkedin:before { content: "\f150"; }
.flaticon-youtube:before { content: "\f151"; }
.flaticon-skype:before { content: "\f152"; }
.flaticon-android:before { content: "\f153"; }
.flaticon-right:before { content: "\f154"; }
.flaticon-medal:before { content: "\f155"; }
.flaticon-firefox:before { content: "\f156"; }
.flaticon-emoji:before { content: "\f157"; }
.flaticon-classified:before { content: "\f158"; }
.flaticon-author:before { content: "\f159"; }
.flaticon-html:before { content: "\f15a"; }
.flaticon-icon-546448:before { content: "\f15b"; }
.flaticon-mobile-phone:before { content: "\f15c"; }
.flaticon-laptop:before { content: "\f15d"; }